<template>
  <layout :dividers="false">
    <template slot="header">
      {{ 'Custom period' }}
    </template>
    <v-form>
      <form-subheader title="From" />
      <div>
        <form-date-picker key="fromDate" v-model="innerFromDate" label="Date" />
        <form-time-picker key="fromTime" v-model="innerFromTime" label="Time" />
      </div>
      <form-subheader title="To" />
      <div>
        <form-date-picker key="toDate" v-model="innerToDate" label="Date" />
        <form-time-picker key="toTime" v-model="innerToTime" label="Time" />
      </div>
    </v-form>
    <template slot="footer">
      <v-spacer />
      <v-btn text @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn color="primary" text @click="submit">
        Play
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { onMounted, ref } from '@vue/composition-api';
import { toISOStringWithTimezone } from '@/utils';
export default {
  name: 'CustomDateRangePopup',
  components: {
    Layout
  },
  props: {
    onSubmit: {
      type: Function,
      default: () => ({})
    },
    fromDate: {
      type: String
    },
    toDate: {
      type: String
    },
    fromTime: {
      type: String
    },
    toTime: {
      type: String
    }
  },
  setup(props, { emit }) {
    const innerFromDate = ref(props.fromDate);
    const innerToDate = ref(props.toDate);
    const innerFromTime = ref(props.fromTime);
    const innerToTime = ref(props.toTime);
    const submit = () => {
      props.onSubmit({
        fromDate: innerFromDate.value,
        toDate: innerToDate.value,
        fromTime: innerFromTime.value,
        toTime: innerToTime.value
      });
      emit('close');
    };

    return {
      innerFromDate,
      innerFromTime,
      innerToDate,
      innerToTime,
      submit
    };
  }
};
</script>

<style lang="sass"></style>
